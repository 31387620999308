<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.write_off_products") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
            >{{ $t("message.create") }}</el-button
          >
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}</el-checkbox
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.date.show">{{ columns.date.title }}</th>
            <th v-if="columns.sender.show">{{ columns.sender.title }}</th>
            <th v-if="columns.receiver.show">{{ columns.receiver.title }}</th>
            <th v-if="columns.warehouse.show">{{ columns.warehouse.title }}</th>
            <th v-if="columns.products.show">{{ columns.products.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <th v-if="columns.updated_at.show">
              {{ columns.updated_at.title }}
            </th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.date.show">
              <el-date-picker
                clearable
                size="mini"
                v-model="filterForm.date"
                :placeholder="columns.date.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.sender.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.sender_id"
                :placeholder="columns.sender.title"
              ></el-input>
            </th>
            <th v-if="columns.receiver.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.receiver_id"
                :placeholder="columns.receiver.title"
              ></el-input>
            </th>
            <th v-if="columns.warehouse.show">
              <warehouses v-model="filterForm.warehouse_id"></warehouses>
            </th>
            <th></th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="writeOff in list"
            :key="writeOff.id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ writeOff.id }}</td>
            <td v-if="columns.date.show">{{ writeOff.date }}</td>
            <td v-if="columns.sender.show">
              {{ writeOff.sender ? writeOff.sender.name : "" }}
            </td>
            <td v-if="columns.receiver.show">
              {{ writeOff.receiver ? writeOff.receiver.name : "" }}
            </td>
            <td v-if="columns.warehouse.show">
              {{ writeOff.warehouse ? writeOff.warehouse.name : "" }}
            </td>
            <td v-if="columns.products.show">
              <span
                @click="showItems(writeOff)"
                style="cursor: pointer; color: #3490dc"
                >Просмотр</span
              >
            </td>
            <td v-if="columns.created_at.show">{{ writeOff.created_at }}</td>
            <td v-if="columns.updated_at.show">{{ writeOff.updated_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="writeOff"
                name="writeOffs"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
    <el-drawer
      size="85%" :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <create-form
        :drawer-name="drawer.create.name"
        :ref="drawer.create.component"
      />
    </el-drawer>

    <el-drawer
      size="85%" :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :with-header="false"
      :ref="drawer.update.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <update-form
        :drawer-name="drawer.update.name"
        :ref="drawer.update.component"
        :write-off="selectedItem"
      />
    </el-drawer>

    <el-dialog
      :title="$t('message.products')"
      :visible.sync="isItemsModelVisible"
      width="80%"
      :before-close="onCloseDialog"
    >
      <el-table
        :data="writeOffProducts"
        v-if="isLoadingItems"
        style="width: 100%"
        border
      >
        <el-table-column prop="id" label="#" width="30"></el-table-column>
        <el-table-column prop="product.name" label="Продукт"></el-table-column>
        <el-table-column prop="quantity" label="Количество"></el-table-column>
        <el-table-column
          prop="created_at"
          label="Дата создания"
        ></el-table-column>
        <el-table-column
          prop="updated_at"
          label="Дата изменения"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import CreateForm from "./components/CreateForm";
import UpdateForm from "./components/UpdateForm";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import warehouses from "@/components/inventory-select/warehouses";

export default {
  mixins: [list, drawer],
  components: {
    CreateForm,
    UpdateForm,
    warehouses,
  },
  data: () => ({
    loadingData: false,
    isItemsModelVisible: false,
    isLoadingItems: false,
    selectedItem: null,
    drawer: {
      create: {
        name: "create",
        component: "componentCreate",
        status: false,
      },
      update: {
        name: "update",
        component: "componentUpdate",
        status: false,
      },
    },
    actions: ["edit", "delete"],
  }),
  computed: {
    ...mapGetters({
      list: "writeOffs/list",
      columns: "writeOffs/columns",
      pagination: "writeOffs/pagination",
      filter: "writeOffs/filter",
      sort: "writeOffs/sort",
    }),
  },
  methods: {
    ...mapActions({
      getWriteOffs: "writeOffs/index",
      updateSort: "writeOffs/updateSort",
      updateFilter: "writeOffs/updateFilter",
      updateColumn: "writeOffs/updateColumn",
      updatePagination: "writeOffs/updatePagination",
      showWriteOff: "writeOffs/show",
      empty: "writeOffs/empty",
      delete: "writeOffs/destroy",
      refresh: "writeOffs/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.getWriteOffs(query)
          .then((response) => {
            this.loadingData = false;
          })
          .catch((error) => {
            this.loadingData = false;
          });
      }
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    refreshData() {
      this.refresh().then(() => {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
      });
    },
    async edit(model) {
      this.selectedItem = model;
      this.drawer.update.status = true;
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showItems(model) {
      this.isItemsModelVisible = true;
      this.showWriteOff(model.id)
        .then((res) => {
          this.writeOffProducts = res.data.write_off.items;
          this.isLoadingItems = true;
        })
        .catch((err) => {
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    onCloseDialog(done) {
      done();
      this.empty();
      this.isLoadingItems = false;
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
    drawerClosed(ref) {
      this.$refs[ref].closed();
    },
  },
};
</script>
